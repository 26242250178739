import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import Button from 'ui-kit/button/button';
import InfoBox from 'ui-kit/info-box/info-box';

import { AutoRefillLearnMoreModal } from 'components/auto-refill-learn-more-modal';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import './auto-refill-banner.style.scss';

export interface AutoRefillBannerProps {
    currentPage?: 'prescriptions' | 'order-confirmation';
}

const AutoRefillBanner = ({ currentPage = 'prescriptions' }: AutoRefillBannerProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleAutoRefillModal = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                ctas: [
                    {
                        label: t('modals.autoRefillLearnMore.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ],
                bodyContent: <AutoRefillLearnMoreModal t={t} />
            })
        );
    }, [dispatch, t]);

    return (
        <div className="auto-refill-banner">
            <InfoBox
                button={
                    <Button
                        onClick={handleAutoRefillModal}
                        variant="text-blue"
                        label={t('pages.medicineCabinet.autoRefillLearnMoreLink')}
                        type="button"
                    />
                }
            >
                <>
                    {currentPage === 'prescriptions' ? (
                        <>
                            <h4 className="title">{t('pages.medicineCabinet.autoRefillLearnTitle')}</h4>
                            <p className="description">{t('pages.medicineCabinet.autoRefillLearnBody')}</p>
                        </>
                    ) : (
                        <p
                            className="order-confirmation"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(t('pages.easyRefill.orderConfirmation.autoRefillLearnMoreText'), {
                                    allowedTags: ['strong']
                                })
                            }}
                        />
                    )}
                </>
            </InfoBox>
        </div>
    );
};

export default AutoRefillBanner;
