import { ALLOW_INSURED_BIRDI_PRICE, OVERRIDE_ADJUDICATION } from 'gatsby-env-variables';

import { DrugWithDiscountPrice } from 'state/drug/drug.reducers';

import {
    CaregiverCartPayload,
    EasyRefillPatientDataCaregiver,
    EasyRefillRxResult,
    ExtendedEasyRefillCartObjectPayload,
    ExtendedEasyRefillRxs,
    PlanResult,
    RefillRx
} from 'types/easy-refill';

import { getBirdiPricePlanNum, isInBirdiFormulary, lowercaseAndCapitalize } from './cart';
import { safeParseFloat } from './number';

// DRX-2425: In future, we should merge the logic for both carts, mantaining the same parameters.

/**
 * Processes an array of carts returned from the API based on specific rules.
 *
 * @param carts Array of carts retrieved from the API.
 * @param accountHasInsurance Indicates whether the account has insurance.
 * @param prescriptionsObject Full prescription object with prescription details.
 * @param drugDiscountPrices Discounts obtained from the DrugPriceApi.
 * @param accountPlans Validated account plans for each cart.
 * @returns An array of processed carts based on specified rules.
 */

export function processEasyRefillCart(
    carts: CaregiverCartPayload[],
    accountHasInsurance: boolean,
    prescriptionsObject: RefillRx[],
    drugDiscountPrices: DrugWithDiscountPrice[],
    accountPlans: PlanResult[],
    zipCode = ''
): ExtendedEasyRefillCartObjectPayload[] {
    const result: ExtendedEasyRefillCartObjectPayload[] = carts?.map((cart) => {
        const extendedCart: ExtendedEasyRefillCartObjectPayload = {
            ...cart.order
        } as ExtendedEasyRefillCartObjectPayload;
        let itemHasUnknownPrice = false;

        const cartItemsObject: ExtendedEasyRefillRxs[] = (cart.order?.refillRxs ?? []).map((item: RefillRx) => {
            const extendedItems: ExtendedEasyRefillRxs = {
                ...item
            } as ExtendedEasyRefillRxs;

            return extendedItems;
        });

        /** Filter thr `accountPlans` for indivdual price validation*/
        const plans = accountPlans?.filter((plan) => {
            if (!plan?.epostPatientNum) return false;
            return plan?.epostPatientNum === cart.epostPatientNum;
        });

        const birdiPricePlanNum: string | undefined = getBirdiPricePlanNum(plans);
        const isBirdiCash = plans?.find((f) => ['BRD01', 'BRD02'].includes(f.planAlias))?.planAlias ? true : false;

        if (cartItemsObject?.length > 0) {
            const updatedRefillRxs: ExtendedEasyRefillRxs[] = [];
            let calculatedOrderTotal = 0;

            cartItemsObject.map((item: ExtendedEasyRefillRxs) => {
                // Clone the item to avoid modifying the original object
                const updatedLineItem: ExtendedEasyRefillRxs = { ...item };

                // Set default values for additional properties
                updatedLineItem.birdiPlanNum = birdiPricePlanNum;
                updatedLineItem.hasKnownPrice = false;

                // Find the details for the current prescription that is in the cart.
                const currentFullPrescription = prescriptionsObject?.find(
                    (obj: RefillRx) => obj.rxNumber === item.rxNumber
                );

                // Check if the prescription is in the Birdi formulary and update prices accordingly
                const prescriptionInBirdiFormulary = isInBirdiFormulary(item.rxNumber, zipCode, drugDiscountPrices);
                if (prescriptionInBirdiFormulary) {
                    updatedLineItem.awpPrice = prescriptionInBirdiFormulary.awpPrice;
                    updatedLineItem.birdiPrice = prescriptionInBirdiFormulary.price;
                    updatedLineItem.showStrikeThruPricing = showStrikeThruPricingEasyRefill(
                        updatedLineItem,
                        accountHasInsurance
                    );
                    updatedLineItem.showBirdiPricing = showBirdiPricingEasyRefill(updatedLineItem, accountHasInsurance);
                }

                // Check if Birdi pricing is being used for the item
                updatedLineItem.isUsingBirdiPrice = itemIsUsingBirdiPriceEasyRefill(
                    updatedLineItem,
                    accountHasInsurance,
                    isBirdiCash
                );

                // Check if the item's price is known based on the current prescription
                updatedLineItem.hasKnownPrice = currentFullPrescription
                    ? isItemPriceKnownEasyRefill(
                          updatedLineItem,
                          currentFullPrescription.prescriptionDetail as EasyRefillRxResult,
                          accountHasInsurance
                      )
                    : false;

                // Update itemHasUnknownPrice flag
                if (!updatedLineItem.hasKnownPrice) {
                    itemHasUnknownPrice = true;
                } else {
                    // Update calculatedOrderTotal based on pricing information
                    if (updatedLineItem.showStrikeThruPricing || updatedLineItem.showBirdiPricing) {
                        updatedLineItem.planNum = birdiPricePlanNum!;
                        if (updatedLineItem.birdiPrice) {
                            calculatedOrderTotal += Number(updatedLineItem.birdiPrice);
                        }
                    } else {
                        if (updatedLineItem.patientCopay) calculatedOrderTotal += updatedLineItem.patientCopay;
                    }
                }

                // Handle birdiPriceNumeric and set finalPrice based on isUsingBirdiPrice
                let birdiPriceNumeric = Number(updatedLineItem.birdiPrice);
                birdiPriceNumeric = isNaN(birdiPriceNumeric) ? 0 : birdiPriceNumeric;
                updatedLineItem.finalPrice = updatedLineItem.isUsingBirdiPrice
                    ? birdiPriceNumeric
                    : updatedLineItem.patientCopay;

                // Handle disclaimer message properties as the error statuses are treathed different in the two cart,
                // we validate here in a different way. To know here whe use ´rxLineErrorString´

                if (updatedLineItem.rxLineError === RX_LINE_ERROR.ADJUDICATE_RX_ERROR) {
                    updatedLineItem.disclaimerTranslationKey = 'pages.cart.rxItemErrorMessageDiscount';
                    updatedLineItem.showDisclaimer = true;
                } else if (
                    !hasAdjudicatedPriceInEasyRefill(updatedLineItem, currentFullPrescription?.prescriptionDetail) &&
                    updatedLineItem.hasKnownPrice &&
                    (updatedLineItem.showStrikeThruPricing || updatedLineItem.showBirdiPricing)
                ) {
                    // Set disclaimer translation key based on rxLineError
                    updatedLineItem.disclaimerTranslationKey =
                        updatedLineItem?.rxLineError === RX_LINE_ERROR.NO_CONTRACT_WITH_PHARMACY
                            ? 'pages.cart.rxItemNoPharmacyContractErrorMessage'
                            : updatedLineItem?.rxLineError === RX_LINE_ERROR.NO_LONGER_COVERED
                            ? 'pages.cart.rxItemNoLongerCoveredErrorMessage'
                            : 'pages.cart.rxItemNotCoveredErrorMessage';

                    // Set showDisclaimer based on conditions
                    updatedLineItem.showDisclaimer = true;
                } else {
                    updatedLineItem.disclaimerTranslationKey = undefined;
                    updatedLineItem.showDisclaimer = false;
                }

                // Add the updated item to the array
                updatedRefillRxs.push(updatedLineItem);
            });

            extendedCart.itemHasUnknownPrice = itemHasUnknownPrice;
            extendedCart.extendedRefillRxs = updatedRefillRxs;
            extendedCart.orderTotal = calculatedOrderTotal;
            extendedCart.firstName = cart.firstName;
            extendedCart.lastName = cart.lastName;
        }
        return extendedCart;
    });
    return result;
}

/**
 * DRX-1762:This ones are the error codes thats being returning from the API.
 * This is a temporary solution cause the API must adjust with the medicine
 * cabinet pattern.
 * */

// Define a mapping of numeric error codes to corresponding strings
export const RX_LINE_ERROR = {
    NONE: 0,
    ADD_ORDER_LINE_ERROR: 1,
    ADJUDICATE_RX_ERROR: 2,
    NO_CONTRACT_WITH_PHARMACY: 40,
    NO_LONGER_COVERED: 65,
    NOT_COVERED: 70
} as const;

// Define a union type to represent the possible values of RxLineErrorCode
export type RxLineErrorCode = typeof RX_LINE_ERROR[keyof typeof RX_LINE_ERROR];

/**
 * Define an array of error codes related to insurance issues
 * that result in the birdi price being displayed
 */
const birdiPriceRxLineErrorCodes: ReadonlyArray<RxLineErrorCode> = [
    RX_LINE_ERROR.NO_CONTRACT_WITH_PHARMACY,
    RX_LINE_ERROR.NO_LONGER_COVERED,
    RX_LINE_ERROR.NOT_COVERED
];

// As the price validation for the easyrefill flow is different we need to replicate
// this code to avoid if stament and make function to complex to manage.
export function hasAdjudicatedPriceInEasyRefill(
    item: ExtendedEasyRefillRxs,
    prescriptionDetails?: Partial<EasyRefillRxResult> | undefined
): boolean {
    if (item) {
        // TO TEST THE CART PAGES AS IF ADJUDICATION IS ON, UPDATE THE ENVIRONMENT VARIABLE
        if (OVERRIDE_ADJUDICATION) {
            return true;
        }

        if (item.rxLineError === RX_LINE_ERROR.NONE) {
            return true;
        } else {
            if (prescriptionDetails) {
                if (
                    item.messageStatus &&
                    prescriptionDetails.webEligibilityStatus !== 'NOT_ELIGIBLE' &&
                    prescriptionDetails.webEligibilityStatus !== 'AUTH_REQ'
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return item.messageStatus;
            }
        }
    }
    return false;
}

export function isItemPriceKnownEasyRefill(
    item: ExtendedEasyRefillRxs,
    prescriptionDetails: EasyRefillRxResult | undefined,
    accountHasInsurance: boolean
): boolean {
    if (
        hasAdjudicatedPriceInEasyRefill(item, prescriptionDetails) ||
        (!hasAdjudicatedPriceInEasyRefill(item, prescriptionDetails) &&
            accountHasInsurance &&
            ALLOW_INSURED_BIRDI_PRICE &&
            (showStrikeThruPricingEasyRefill(item, accountHasInsurance) ||
                showBirdiPricingEasyRefill(item, accountHasInsurance)))
    ) {
        return true;
    } else {
        return false;
    }
}

export function showStrikeThruPricingEasyRefill(item: ExtendedEasyRefillRxs, accountHasInsurance: boolean): boolean {
    if (
        accountHasInsurance &&
        ALLOW_INSURED_BIRDI_PRICE &&
        birdiPriceRxLineErrorCodes.includes(item.rxLineError as RxLineErrorCode) &&
        item?.awpPrice !== 'NA' &&
        parseFloat(item?.awpPrice) !== 0 &&
        item.birdiPrice !== 'NA' &&
        parseFloat(item?.awpPrice) > parseFloat(item.birdiPrice)
    ) {
        return true;
    } else {
        return false;
    }
}

export function showBirdiPricingEasyRefill(item: ExtendedEasyRefillRxs, accountHasInsurance: boolean): boolean {
    if (
        accountHasInsurance &&
        ALLOW_INSURED_BIRDI_PRICE &&
        birdiPriceRxLineErrorCodes.includes(item.rxLineError as RxLineErrorCode) &&
        (item?.awpPrice === 'NA' || safeParseFloat(item?.awpPrice) <= safeParseFloat(item.birdiPrice)) &&
        item.birdiPrice !== 'NA'
    ) {
        return true;
    } else {
        return false;
    }
}

export function itemIsUsingBirdiPriceEasyRefill(
    item: ExtendedEasyRefillRxs,
    accountHasInsurance: boolean,
    isBirdiCash?: boolean
) {
    // for BRD01 and BRD02
    if (isBirdiCash && !accountHasInsurance && Number(item.birdiPrice) > 0) {
        return true;
    }

    // for insuredAccounts
    if (
        accountHasInsurance &&
        ((item.birdiPlanNum && item.planNum === item.birdiPlanNum) ||
            item.showStrikeThruPricing ||
            item.showBirdiPricing)
    ) {
        return true;
    } else {
        return false;
    }
}

/********************************************************************************************/

const mapFamilyMembers = (familyMembers: EasyRefillPatientDataCaregiver[], willUseEposPatienNum: boolean) => {
    return familyMembers.map(({ epostPatientNum, firstName, lastName }) => {
        const patientName = `${firstName} + ${lastName}`;

        return {
            key: patientName,
            label: lowercaseAndCapitalize(patientName),
            value: willUseEposPatienNum
                ? epostPatientNum
                : [...lowercaseAndCapitalize(patientName).split(' '), epostPatientNum]
        };
    });
};

export const mapEasyRefillFamilyMembers = (
    familyMembers: EasyRefillPatientDataCaregiver[],
    willUseEposPatienNum: boolean
) => {
    const dependents = familyMembers.length > 0 ? mapFamilyMembers(familyMembers, willUseEposPatienNum) : [];

    return [...dependents];
};
