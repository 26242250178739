// General
import { graphql, navigate } from 'gatsby';
import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { ENABLE_AUTO_REFILL } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// Ui-kit
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import Link from 'ui-kit/link/link';
import PageSection from 'ui-kit/page-section/page-section';

// Components
import AutoRefillBanner from 'components/auto-refill-banner/auto-refill-banner.component';
import { AutoRefillTermsAndConditionsModal } from 'components/auto-refill-terms-and-conditions-modal';
import { CartOrderedTotal } from 'components/easy-refill/cart-ordered-total.component';
import { PrescriptionInformation } from 'components/easy-refill/review/prescription-information.component';
import InternalHeader from 'components/internal-header/internal-header.component';
import MarketingpageLayout from 'components/layouts/page/marketingpage.layout';
import { PrescriptionContentSectionHeader } from 'components/prescription-content-section-header';

// State
import { closeModal, openModal, setBusyModal } from 'state/birdi-modal/birdi-modal.reducers';
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { drugSelector } from 'state/drug/drug.selectors';
import { easyRefillToggleAutoRefillRxStatusRoutine } from 'state/easy-refill/easy-refill.routines';
import {
    autoRefillFlagsSelector,
    cartSelector,
    easyRefillAccountHasInsuranceSelector,
    easyRefillCartItemsSelector,
    easyRefillCartTotalSelector,
    easyRefillIsCaregiverSelector,
    easyRefillOrderAddressSelector,
    easyRefillOrderBillShipMethodSelector,
    easyRefillOrderNumberSelector,
    easyRefillOrderPaymentCardSelector,
    easyRefillPatientBillAddressSelector,
    easyRefillPlansSelector,
    easyRefillRxsToOrderSelector,
    easyRefillUserBearerTokenSelector
} from 'state/easy-refill/easy-refill.selectors';

// Types
import {
    EasyRefillCaregiverCartData,
    EasyRefillCaregiverCartPrescriptionDetailData,
    ExtendedEasyRefillCartObjectPayload
} from 'types/easy-refill';

// Util
import { DEFAULT_SHIPPING_ID, EXPEDITED_SHIPPING_COST, EXPEDITED_SHIPPING_ID } from 'util/cart';
import { processEasyRefillCart } from 'util/easy-refill';
import storageHelper from 'util/storageHelper';
import { replaceStringWith } from 'util/string';

// Hooks
import { useEasyRefill } from 'hooks/useEasyRefill';

// HOC
import withUnauthenticatedSessionExpiration from 'hoc/withUnauthenticatedSessionExpiration';

// Styles
import './index.style.scss';

// Helper function to update the cart with the Auto Refill flag set as true.
const getUpdatedRxAutoRefillOn = (
    cartItems: EasyRefillCaregiverCartData[],
    rxSeqNumToUpdate: string[]
): EasyRefillCaregiverCartData[] => {
    const updatedCartItems = cartItems.map((rx) => {
        if (rxSeqNumToUpdate.includes(rx.prescriptionDetail.rxSeqNum)) {
            return {
                ...rx,
                prescriptionDetail: { ...rx.prescriptionDetail, autoFillStatus: 'Y', autoRefillEnabled: true }
            };
        } else {
            return rx;
        }
    });

    return updatedCartItems;
};

// Main component
const EasyRefillOrderConfirmation = ({ data }: { data: GatsbyTypes.EasyRefillOrderConfirmationDataQuery }) => {
    // General
    const { blueSkyBackground } = data;

    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { showBirdiPricing } = useEasyRefill();

    // Hooks: states
    const [reviewCartItems, setReviewCartItems] = useState<EasyRefillCaregiverCartData[]>([]);
    const [rxWithAutoRefill, setRxWithAutoRefill] = useState<string[]>([]);
    const [extendedCartObject, setExtendedCartObject] = useState<ExtendedEasyRefillCartObjectPayload[] | undefined>(
        undefined
    );

    // Selectors: before Caregivers implementation
    const shipMethodId = useSelector(easyRefillOrderBillShipMethodSelector);
    const easyRefillRxsToOrder = useSelector(easyRefillRxsToOrderSelector);
    const orderNumber = useSelector(easyRefillOrderNumberSelector);
    const orderShippingAddress = useSelector(easyRefillOrderAddressSelector);
    const defaultPaymentCard = useSelector(easyRefillOrderPaymentCardSelector);
    const easyRefillBearerToken = useSelector(easyRefillUserBearerTokenSelector);
    const autorefillFlags = useSelector(autoRefillFlagsSelector);
    const profileDefaultShippingAddress = useSelector(easyRefillPatientBillAddressSelector)

    // Selectors: after Caregivers implementation
    const easyRefillIsCaregiver = useSelector(easyRefillIsCaregiverSelector);
    const accountHasInsured = useSelector(easyRefillAccountHasInsuranceSelector);
    const easyRefillCartItems = useSelector(easyRefillCartItemsSelector);
    const { drugDiscountPrices } = useSelector(drugSelector);
    const cartsObject = useSelector(cartSelector);
    const accountPlans = useSelector(easyRefillPlansSelector);
    const totalOrderValue = useSelector(easyRefillCartTotalSelector);

    // The autorefill banner should be hidden if one of these conditions applies:
    const shouldDisplayAutoRefillInfo = useMemo(() => {
        // 1. Check if the patient's primary plan doesn't allow auto-refill.
        const isPlanIneligible = autorefillFlags?.some((item) => item.planAutoRefillEligible === false);

        const isAddressInCalifornia = profileDefaultShippingAddress?.state === 'CA';

        // TODO: 3. Check if the patient's account-level auto-refill flag is already ON.
        // This is a temporary solution, as we need to validate the autorefill at the plan level
        // However, we are not getting this flag yet, so we must impleent this valdiation for Medicpact
        // users. This will require refactoring in the future. For more information look at this comment.
        // https://mincainc.atlassian.net/browse/DRX-4067?focusedCommentId=109267

        return !(isPlanIneligible || isAddressInCalifornia);
    }, [autorefillFlags, profileDefaultShippingAddress]);

    // DRX-3165: This useEffect marks an order placement by setting a cookie. Since we handle family members,
    // it's possible for the user to navigate back and add more refills for their family members. so we ensure
    // that when the user goes back it would be redirected to the prescriptions page.
    // More info here: https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event
    useEffect(() => {
        storageHelper.cookies.setHasOrderEasyRefillPlaced();
        const handlePopState = () => {
            navigate('/easy-refill/prescriptions', { replace: true });
        };
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        if (cartsObject) {
            const extendedCart = processEasyRefillCart(
                cartsObject,
                accountHasInsured,
                easyRefillCartItems,
                drugDiscountPrices,
                accountPlans
            );

            setExtendedCartObject(extendedCart);
        }
    }, [accountHasInsured, accountPlans, cartsObject, drugDiscountPrices, easyRefillCartItems]);

    useEffect(() => {
        if (showBirdiPricing) {
            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions: easyRefillRxsToOrder,
                    forceBirdiInsurance: ALLOW_INSURED_BIRDI_PRICE
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBirdiPricing, dispatch]);

    const handleAutoRefillToggle = useCallback(
        (autoRefillRxs: EasyRefillCaregiverCartPrescriptionDetailData[], autoRefillEnabled: boolean) => {
            autoRefillRxs.forEach(async (rx) => {
                dispatch(
                    easyRefillToggleAutoRefillRxStatusRoutine.trigger({
                        rxsToToggle: {
                            rxSeqNum: rx.rxSeqNum,
                            newStatus: autoRefillEnabled,
                            rxNumber: rx.rxNumber
                        },
                        onSuccess: () => {
                            const autoRefillList = [...rxWithAutoRefill, rx.rxSeqNum];

                            setRxWithAutoRefill(autoRefillList);
                            setReviewCartItems(getUpdatedRxAutoRefillOn(reviewCartItems, autoRefillList));

                            dispatch(closeModal({}));
                        }
                    })
                );
            });
        },
        [dispatch, reviewCartItems, rxWithAutoRefill]
    );

    const handleShowTCModal = useCallback(
        (rxNumbers: EasyRefillCaregiverCartPrescriptionDetailData[], autoRefillEnabled: boolean) => {
            dispatch(
                openModal({
                    onClose: () => {
                        dispatch(closeModal({}));
                    },
                    ctas: [
                        {
                            label: t('modals.autoRefillTC.confirm'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(setBusyModal(true));
                                handleAutoRefillToggle(rxNumbers, autoRefillEnabled);
                            },
                            async: true,
                            dataGALocation: 'UnauthenticatedAutoRefillTCConfirm'
                        },
                        {
                            label: t('modals.autoRefillTC.cancel'),
                            variant: 'text',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'UnauthenticatedAutoRefillTCCancel',
                            className: 'p-4'
                        }
                    ],
                    bodyContent: <AutoRefillTermsAndConditionsModal t={t} />,
                    showClose: true
                })
            );
        },
        [dispatch, handleAutoRefillToggle, t]
    );

    const handleShowAutoRefillToggleModal = useCallback(
        (rxNumbers: EasyRefillCaregiverCartPrescriptionDetailData[], autoRefillEnabled: boolean) => {
            if (autoRefillEnabled) {
                handleShowTCModal(rxNumbers, autoRefillEnabled);
            } else {
                handleAutoRefillToggle(rxNumbers, autoRefillEnabled);
            }
        },
        [handleAutoRefillToggle, handleShowTCModal]
    );

    useEffect(() => {
        if (!easyRefillBearerToken) {
            navigate('/link-expired?flow=easy-refill');
        }
    }, [easyRefillBearerToken]);

    return (
        <>
            <MarketingpageLayout
                headerImage={blueSkyBackground}
                headerImageClassName="profile-background"
                metaData={{ nodeTitle: 'Easy Refill' }}
                suppressApplicationPage={true}
            >
                <PageSection>
                    <Container fluid className={`easy-refill-order-confirmation`}>
                        <CartIcon className={'easy-refill-order-confirmation-icon-header'} />
                        <InternalHeader
                            title={t('pages.easyRefill.orderConfirmation.headlineText')}
                            eyebrowText={t('pages.easyRefill.orderConfirmation.eyebrowText')}
                            sectionIndex={0}
                            columnWidthOnDesktop={10}
                            variant="blue-background"
                            showSpacer
                        />
                        <Row>
                            <Col xs={12} md={12} lg={9} className="easy-refill-order-confirmation-main-container">
                                <Row className="easy-refill-order-confirmation-order-message">
                                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                        <h4
                                            dangerouslySetInnerHTML={{
                                                __html: t('pages.easyRefill.orderConfirmation.orderMessage', {
                                                    orderNumber: orderNumber
                                                })
                                            }}
                                        ></h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                        <PrescriptionContentSectionHeader
                                            label={t(
                                                'pages.easyRefill.orderConfirmation.sectionHeaders.prescriptionInformation'
                                            )}
                                            pricingLabel={t('pages.cart.pricing')}
                                            className="easy-refill-layout-content-section-header"
                                        />
                                        <PrescriptionInformation
                                            t={t}
                                            fullCart={extendedCartObject || []}
                                            prescriptions={reviewCartItems}
                                            isCaregiver={easyRefillIsCaregiver}
                                            accountHasInsurance={accountHasInsured}
                                            rxWithAutoRefill={rxWithAutoRefill}
                                            onPrescriptionAutoRefillChange={handleShowAutoRefillToggleModal}
                                            isOrderCompleted={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                        <CartOrderedTotal
                                            t={t}
                                            estimatedOrderPrice={totalOrderValue}
                                            shippingPrice={
                                                shipMethodId === EXPEDITED_SHIPPING_ID ? EXPEDITED_SHIPPING_COST : 0
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                        <PrescriptionContentSectionHeader
                                            label={t(
                                                'pages.easyRefill.orderConfirmation.sectionHeaders.shippingInformation'
                                            )}
                                            className="easy-refill-layout-content-section-header"
                                        />
                                        <Row>
                                            <Col className="easy-refill-order-confirmation-section-content p-0 mt-1">
                                                <div className="easy-refill-order-confirmation-default-address-label">
                                                    <span>
                                                        {t(
                                                            'pages.easyRefill.orderConfirmation.shippingInformation.defaultAddressLabel'
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="easy-refill-order-confirmation-default-address">
                                                    {orderShippingAddress && (
                                                        <>
                                                            <span className="easy-refill-order-confirmation-section-sentence">
                                                                {orderShippingAddress.address1}{' '}
                                                                {orderShippingAddress.address2
                                                                    ? orderShippingAddress.address2
                                                                    : ''}
                                                            </span>
                                                            <span className="easy-refill-order-confirmation-section-sentence">
                                                                {orderShippingAddress.city},{' '}
                                                                {orderShippingAddress.state}{' '}
                                                                {orderShippingAddress.zipcodeFour
                                                                    ? `${orderShippingAddress.zipcode}-${orderShippingAddress.zipcodeFour}`
                                                                    : orderShippingAddress.zipcode}
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                        <PrescriptionContentSectionHeader
                                            label={t(
                                                'pages.easyRefill.orderConfirmation.sectionHeaders.shippingMethod'
                                            )}
                                            className="easy-refill-layout-content-section-header"
                                        />
                                        <Row>
                                            <Col className="mt-1 mb-3">
                                                <span>
                                                    {shipMethodId === DEFAULT_SHIPPING_ID
                                                        ? t('pages.cart.freeShipping')
                                                        : t('pages.cart.expeditedShipping')}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="h6 easy-refill-order-confirmation-footer-info">
                                                    {t('pages.cart.shippingFooter')}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                        <PrescriptionContentSectionHeader
                                            label={t(
                                                'pages.easyRefill.orderConfirmation.sectionHeaders.paymentInformation'
                                            )}
                                            className="easy-refill-layout-content-section-header"
                                        />
                                        <Row>
                                            <Col className="easy-refill-order-confirmation-section-content p-0 mt-1">
                                                <div className="easy-refill-order-confirmation-default-payment-method-label">
                                                    <span>
                                                        {t(
                                                            'pages.easyRefill.orderConfirmation.paymentInformation.primaryPaymentMethod'
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="easy-refill-order-confirmation-default-payment-method">
                                                    {defaultPaymentCard && (
                                                        <>
                                                            <span>
                                                                {t(
                                                                    'pages.easyRefill.orderConfirmation.paymentInformation.cardEnding',
                                                                    {
                                                                        cardType: defaultPaymentCard.cardType,
                                                                        endingDigits: replaceStringWith(
                                                                            defaultPaymentCard.secureCardNumber,
                                                                            new RegExp(/\*/g),
                                                                            ''
                                                                        )
                                                                    }
                                                                )}
                                                                <br />
                                                                {defaultPaymentCard.cardName}
                                                                <br />
                                                                {defaultPaymentCard.cardExpiration}
                                                            </span>
                                                            <CreditCardIcon
                                                                className="payment-card__credit-card-icon"
                                                                variant={defaultPaymentCard.cardType}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {/* will be implemented on DRX-1765 */}
                                {ENABLE_AUTO_REFILL && shouldDisplayAutoRefillInfo && (
                                    <Row className="py-5">
                                        <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                            <AutoRefillBanner currentPage="order-confirmation" />
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                        <Row>
                                            <Col className="easy-refill-order-confirmation-section-content manage-prescriptions m-3">
                                                <span className="easy-refill-order-confirmation-section-sentence">
                                                    {t(
                                                        'pages.easyRefill.orderConfirmation.managePrescriptions.infoText'
                                                    )}
                                                </span>
                                                <Link
                                                    to={'/sign-in'}
                                                    label={t(
                                                        'pages.easyRefill.orderConfirmation.managePrescriptions.signIn'
                                                    )}
                                                    variant="cta-button"
                                                    dataGALocation="OrderConfirmation-Signin"
                                                    ariaLabel="OrderConfirmation-Signin"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </PageSection>
            </MarketingpageLayout>
        </>
    );
};

export default withUnauthenticatedSessionExpiration(EasyRefillOrderConfirmation, 'easy-refill');

export const query = graphql`
    query EasyRefillOrderConfirmationData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/white-feathers-background-02.png" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
        allMenuLinkContentFooterLegal(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allBlockContentAddresses(sort: { fields: changed, order: DESC }) {
            nodes {
                field_physical_address
                field_mailing_address
                field_specialty_mailing_address
                field_specialty_physical_address
            }
        }
    }
`;
